export default {
  defaultLocale: 'ru',
  locales: [{
    key: 'en', name: 'English', icon: 'us'
  }, {
    key: 'ru', name: 'Русский', icon: 'ru'
  }],
  cookieName: 'fc_locale',
  translations: {
    menu: {
      map: {
        en: 'Map',
        ru: 'Карта'
      },
      irrigation: {
        en: 'Irrigation',
        ru: 'Орошение'
      },
      meteos: {
        en: 'Meteos',
        ru: 'Станции'
      },
      reports: {
        en: 'Reports',
        ru: 'Отчеты'
      },
      help: {
        en: 'Help',
        ru: 'Помощь'
      },
      contact: {
        en: 'Contact',
        ru: 'Контакты'
      },
      login: {
        en: 'Login',
        ru: 'Логин'
      },
      restorePassword: {
        en: 'Restore password',
        ru: 'Восстановление пароля'
      },
      settings: {
        en: 'Settings',
        ru: 'Настройки'
      },
      admin: {
        en: 'Admin Panel',
        ru: 'Администрирование'
      }
    },
    mainMenu: {
      settings: {
        en: 'Settings',
        ru: 'Режим работы'
      },
      map: {
        en: 'Position on the Map',
        ru: 'Положение на карте'
      },
      guns: {
        en: 'End Guns',
        ru: 'Концевые пушки'
      },
      hoseStop: {
        en: 'Hose Stop',
        ru: 'Гидранты'
      },
      camera: {
        en: 'Camera',
        ru: 'Камера'
      },
      pivot: {
        en: 'Pivot',
        ru: 'Пивот'
      },
      systemSettings: {
        en: 'System Settings',
        ru: 'Системные настройки'
      },
      reports: {
        en: 'Reports',
        ru: 'Отчеты'
      }
    },
    form: {
      timezone: {
        en: 'Timezone',
        ru: 'Таймзона'
      },
      dateFormat: {
        en: 'Date format',
        ru: 'Формат даты'
      },
      direction: {
        frw: {
          en: 'Clockwise',
          ru: 'По часовой стрелке'
        },
        rev: {
          en: 'Counter Clockwise',
          ru: 'Против часовой стрелки'
        }
      },
      email: {
        en: 'Email',
        ru: 'Адрес электронной почты'
      },
      password: {
        en: 'Password',
        ru: 'Пароль'
      },
      speed: {
        en: 'Speed',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        ru: 'Норма'
      },
      depthSuffix: {
        en: 'mm',
        ru: 'мм'
      },
      time: {
        en: 'Time',
        ru: 'Время'
      },
      timeSuffix: {
        en: 'h',
        ru: 'ч'
      },
      timeSuffixHour: {
        en: 'h',
        ru: 'ч'
      },
      timeSuffixMinute: {
        en: 'min',
        ru: 'мин'
      },
      pressure: {
        en: 'Pressure',
        ru: 'Давление'
      },
      pressureSuffix: {
        en: 'Bar',
        ru: 'Бар'
      },
      flow: {
        en: 'Flow',
        ru: 'Поток'
      },
      flowSuffix: {
        en: 'l/s',
        ru: 'л/c'
      },
      voltage: {
        en: 'Voltage',
        ru: 'Напряжение'
      },
      voltageSuffix: {
        en: 'Volt',
        ru: 'Вольт'
      },
      temperature: {
        en: 'Temperature',
        ru: 'Температура'
      },
      temperatureSuffix: {
        en: '°C',
        ru: '°C'
      },
      serviceStop: {
        en: 'Service Stop',
        ru: 'Сервисная остановка'
      },
      serviceStopRepeat: {
        en: 'Repeat',
        ru: 'Повтор'
      },
      comment: {
        en: 'Comment',
        ru: 'Комментарий'
      },
      lat: {
        en: 'Latitude',
        ru: 'Широта'
      },
      lng: {
        en: 'Longitude',
        ru: 'Долгота'
      },
      startLat: {
        en: 'Start Latitude',
        ru: 'Начальная широта'
      },
      startLng: {
        en: 'Start Longitude',
        ru: 'Начальная долгота'
      },
      endLat: {
        en: 'End Latitude',
        ru: 'Конечная широта'
      },
      endLng: {
        en: 'End Longitude',
        ru: 'Конечная долгота'
      },
      lateralHeight: {
        en: 'Device Height',
        ru: 'Длина устройства'
      },
      lateralLength: {
        en: 'Run Length',
        ru: 'Длина гона'
      },
      startPosition: {
        en: 'Start Position',
        ru: 'Начальное положение'
      },
      endPosition: {
        en: 'End Position',
        ru: 'Конечное положение'
      },
      radius: {
        en: 'Radius',
        ru: 'Радиус'
      },
      radiusSuffix: {
        en: 'm',
        ru: 'м'
      },
      hoseStopSuffix: {
        en: 'm',
        ru: 'м'
      },
      gunIndex: {
        en: 'Area',
        ru: 'Область'
      },
      gunStart: {
        en: 'Start Angle',
        ru: 'Начальный угол'
      },
      gunEnd: {
        en: 'End Angle',
        ru: 'Конечный угол'
      },
      gunStartLateral: {
        en: 'Start Position',
        ru: 'Начальное положение'
      },
      gunEndLateral: {
        en: 'End Position',
        ru: 'Конечное положение'
      },
      startAngle: {
        en: 'Start Angle',
        ru: 'Начальный угол'
      },
      endAngle: {
        en: 'End Angle',
        ru: 'Конечный угол'
      },
      language: {
        en: 'Language',
        ru: 'Язык'
      },
      user: {
        name: {
          en: 'User Name',
          ru: 'Имя пользователя'
        },
        email: {
          en: 'Email',
          ru: 'Адрес электронной почты'
        },
        password: {
          en: 'Password',
          ru: 'Пароль'
        },
        role: {
          en: 'Role',
          ru: 'Роль'
        },
        organization: {
          en: 'Organization',
          ru: 'Организация'
        },
        allDevices: {
          en: 'All Devices Accessible',
          ru: 'Все устройства доступны'
        },
        allDevicesShort: {
          en: 'All',
          ru: 'Все'
        },
        devices: {
          en: 'Accessible Devices',
          ru: 'Доступные устройства'
        }
      },
      organization: {
        name: {
          en: 'Organization Name',
          ru: 'Название организации'
        },
        devices: {
          en: 'Accessible Devices',
          ru: 'Доступные устройства'
        },
        proxy: {
          ip: {
            en: 'IP',
            ru: 'IP'
          },
          port: {
            en: 'Port',
            ru: 'Порт'
          }
        }
      },
      camera: {
        ip: {
          en: 'IP',
          ru: 'IP'
        },
        port: {
          en: 'Port',
          ru: 'Порт'
        },
        login: {
          en: 'Login',
          ru: 'Логин'
        },
        password: {
          en: 'Password',
          ru: 'Пароль'
        },
        status: {
          en: 'Status',
          ru: 'Статус'
        },
        cameraNum: {
          en: 'Camera No',
          ru: 'Номер камеры'
        }
      },
      settings: {
        timeOffset: {
          en: 'Time Offset',
          ru: 'Таймзона'
        },
        timeOffsetHint: {
          en: 'In hours, relatively to UTC e.g. +1, +2, etc.',
          ru: 'В часах относительно UTC, например +1, +2, итд.'
        }
      },
      hoseStopPosition: {
        en: 'Position',
        ru: 'Позиция'
      },
      hoseStopAlertDistance: {
        en: 'Alert Distance',
        ru: 'Предупреждение'
      }
    },
    actions: {
      requestPivot: {
        en: 'Request Pivot Settings',
        ru: 'Запросить настройки устройства'
      },
      requestPump: {
        en: 'Request Pump Settings',
        ru: 'Запросить настройки насоса'
      },
      startEditEndGuns: {
        en: 'Edit End Guns',
        ru: 'Редактировать концевые пушки'
      },
      edit: {
        en: 'Edit',
        ru: 'Редактировать'
      },
      login: {
        en: 'Log In',
        ru: 'Войти'
      },
      logout: {
        en: 'Log Out',
        ru: 'Выйти'
      },
      settings: {
        en: 'Settings',
        ru: 'Настройки'
      },
      admin: {
        en: 'Admin',
        ru: 'Админ'
      },
      restorePassword: {
        en: 'Restore Password',
        ru: 'Восстановить пароль'
      },
      centerMap: {
        en: 'Center Map',
        ru: 'Центрировать на объектах'
      },
      refresh: {
        en: 'Refresh',
        ru: 'Обновить'
      },
      apply: {
        en: 'Apply',
        ru: 'Применить'
      },
      add: {
        en: 'Add',
        ru: 'Добавить'
      },
      create: {
        en: 'Create',
        ru: 'Создать'
      },
      save: {
        en: 'Save',
        ru: 'Сохранить'
      },
      cancel: {
        en: 'Cancel',
        ru: 'Отмена'
      },
      editOnTheMap: {
        en: 'Edit Position',
        ru: 'Изменить положение на карте'
      },
      request: {
        en: 'Request',
        ru: 'Опросить'
      },
      gotoLogin: {
        en: 'Login',
        ru: 'Войти'
      },
      gotoRestorePassword: {
        en: 'Restore password',
        ru: 'Восстановление пароля'
      },
      close: {
        en: 'Close',
        ru: 'Закрыть'
      },
      userNew: {
        en: 'Create New User',
        ru: 'Создать нового пользователя'
      },
      organizationNew: {
        en: 'Create New Organization',
        ru: 'Создать новую организацию'
      },
      delete: {
        en: 'Delete',
        ru: 'Удалить'
      },
      addCamera: {
        en: 'Add',
        ru: 'Добавить'
      },
      editCamera: {
        en: 'Edit',
        ru: 'Редактировать'
      },
      requestReport: {
        en: 'Load data',
        ru: 'Загрузить отчет'
      },
      find: {
        en: 'Find',
        ru: 'Найти'
      }
    },
    irrigationColumns: {
      equipment: {
        en: 'Name',
        ru: 'Имя'
      },
      status: {
        en: 'Status',
        ru: 'Статус'
      },
      speed: {
        en: 'Speed',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        ru: 'Норма'
      },
      time: {
        en: 'Time',
        ru: 'Время'
      },
      serviceStop: {
        en: 'Serv. Stop',
        ru: 'Серв. Ост.'
      },
      management: {
        en: 'Management',
        ru: 'Управление'
      },
      camera: {
        en: 'Camera',
        ru: 'Камера'
      },
      connection: {
        en: 'Connection',
        ru: 'Связь'
      },
      last_update: {
        en: 'Updated',
        ru: 'Обнов.'
      }
    },
    meteosColumns: {
      id: {
        en: 'Id',
        ru: 'Id'
      },
      name: {
        en: 'Name',
        ru: 'Имя'
      },
      device_name: {
        en: 'Station name',
        ru: 'Имя устройства'
      },
      type: {
        en: 'Type',
        ru: 'Тип'
      },
      connection: {
        en: 'Connection',
        ru: 'Связь'
      },
    },
    status: {
      no_connection: {
        en: 'No Connection',
        ru: 'Нет связи'
      },
      alg_err: {
        en: 'alg_err',
        ru: 'Ошибка выравнивания'
      },
      prsr_low: {
        en: 'prsr_low',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'rst_dly',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'prsr_wait',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'prsr_rcy',
        ru: 'Восст-е давления'
      },
      prsr_min: {
        en: 'prsr_min',
        ru: 'Мин.допустимое давл.'
      },
      over_water: {
        en: 'Over Water',
        ru: 'Over Water'
      },
      gps_error: {
         en: 'GPS Error',
         ru: 'Ошибка GPS'
      },
      service_stop: {
        en: 'Service Stop',
        ru: 'Сервисная остановка'
      },
      acc_frw: {
        en: 'Forward with ACC',
        ru: 'Движение вперед с удобрениями'
      },
      acc_rev: {
        en: 'Reverse with ACC',
        ru: 'Движение назад с удобрениями'
      },
      irrigation_frw: {
        en: 'Forward with irrigation',
        ru: 'Движение вперед с водой'
      },
      irrigation_rev: {
        en: 'Reverse with irrigation',
        ru: 'Движение назад с водой'
      },
      frw: {
        en: 'Forward',
        ru: 'Движение вперед'
      },
      rev: {
        en: 'Reverse',
        ru: 'Движение назад'
      },
      stop: {
        en: 'Stop',
        ru: 'Остановлено'
      },
      move: {
        en: 'Move',
        ru: 'Движ.'
      },
      online: {
        en: 'Online',
        ru: 'Онлайн'
      },
      offline: {
        en: 'Offline',
        ru: 'Оффлайн'
      },
      finish: {
        en: 'Serv.Stop',
        ru: 'Серв.Ост.'
      }
    },
    statusShort: {
      no_connection: {
        en: 'No Connection',
        ru: 'Нет связи'
      },
      alg_err: {
        en: 'alg_err',
        ru: 'Ошибка выравн.'
      },
      prsr_low: {
        en: 'prsr_low',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'rst_dly',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'prsr_wait',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'prsr_rcy',
        ru: 'Восст-е давл.'
      },
      prsr_min: {
        en: 'prsr_min',
        ru: 'Мин.доп.давл.'
      },
      over_water: {
        en: 'Over Water',
        ru: 'Over Water'
      },
      gps_error: {
         en: 'GPS Error',
         ru: 'Ошибка GPS'
      },
      service_stop: {
        en: 'Srv.Stop',
        ru: 'Серв.Ост.'
      },
      acc_frw: {
        en: 'Frw with ACC',
        ru: 'Дв.вперед с удобр.'
      },
      acc_rev: {
        en: 'Rev with ACC',
        ru: 'Дв.назад с удобр.'
      },
      irrigation_frw: {
        en: 'Frw with irrigation',
        ru: 'Дв.вперед с водой'
      },
      irrigation_rev: {
        en: 'Rev with irrigation',
        ru: 'Дв.назад с водой'
      },
      frw: {
        en: 'Frw',
        ru: 'Дв.вперед'
      },
      rev: {
        en: 'Rev',
        ru: 'Дв.назад'
      },
      stop: {
        en: 'Stop',
        ru: 'Останов.'
      }
    },
    workPlan: {
      manual: {
        en: 'Manual management',
        ru: 'Ручное управление'
      },
      auto: {
        en: 'Automatic management',
        ru: 'Автоматическое управление'
      }
    },
    controls: {
      move: {
        off: {
          en: 'Stop',
          ru: 'Остановить'
        },
        frw: {
          en: 'Forward',
          ru: 'Вперед'
        },
        rev: {
          en: 'Reverse',
          ru: 'Назад'
        },
        finish: {
          en: 'Service Stop',
          ru: 'Сервисная остановка'
        }
      },
      irrigation: {
        off: {
          en: 'Irrigaion Off',
          ru: 'Выключить воду'
        },
        on: {
          en: 'Irrigation On',
          ru: 'Включить воду'
        }
      },
      acc: {
        off: {
          en: 'ACC Off',
          ru: 'Выключить ACC'
        },
        on: {
          en: 'ACC On',
          ru: 'Включить ACC'
        }
      },
      autoRev: {
        off: {
          en: 'Autoreverse Off',
          ru: 'Выключить Авторазворот'
        },
        on: {
          en: 'Autoreverse On',
          ru: 'Включить Авторазворот'
        }
      },
      autoStart: {
        off: {
          en: 'Autorestart Off',
          ru: 'Выключить Автоперезапуск'
        },
        on: {
          en: 'Autorestart On',
          ru: 'Включить Автоперезапуск'
        }
      }
    },
    errors: {
      incorrectToken: {
        en: 'Incorrect authentication token',
        ru: 'Неверный токен авторизации'
      },
      formValidation: {
        en: 'Some form fields are not filled correctly',
        ru: 'Некоторые поля формы заполнены неверно'
      },
      incorrectEmailOrPassword: {
        en: 'Incorrect Email or Password',
        ru: 'Неправильный email или пароль'
      },
      fields: {
        noEmail: {
          en: 'Email is not specified',
          ru: 'Емейл не указан'
        },
        noPassword: {
          en: 'Password is not specified',
          ru: 'Пароль не указан'
        }
      },
      updateSnapshot: {
        en: 'Failed receiving the new snapshot',
        ru: 'Ошибка получения нового снимка камеры'
      }
    },
    success: {
      pivotRequested: {
        en: 'Pivot Requested',
        ru: 'Настройки пивота запрошены'
      },
      pumpRequested: {
        en: 'Pump Requested',
        ru: 'Настройки насоса запрошены'
      },
      noSystemSettings: {
        en: 'No System Settings',
        ru: 'Системные настройки не найдены'
      },
      settingsUpdated: {
        en: 'Settings Updated',
        ru: 'Настройки обновлены'
      },
      cameraSettingsUpdated: {
        en: 'Camera Settings Updated',
        ru: 'Настройки камеры обновлены'
      },
      userCreated: {
        en: 'New User Created',
        ru: 'Создан новый пользователь'
      },
      userUpdated: {
        en: 'User Data Updated',
        ru: 'Данные пользователя обновлены'
      },
      userDeleted: {
        en: 'User Deleted',
        ru: 'Пользователь удален'
      },
      organizationCreated: {
        en: 'New Organization Created',
        ru: 'Создана новая организация'
      },
      organizationUpdated: {
        en: 'Organization Data Updated',
        ru: 'Данные организации обновлены'
      },
      organizationDeleted: {
        en: 'Organization Deleted',
        ru: 'Организация удалена'
      },
      loggedIn: {
        en: 'You\'ve logged in',
        ru: 'Вы вошли в систему'
      },
      loggedOut: {
        en: 'You\'ve logged out',
        ru: 'Вы вышли из системы'
      },
      cmdSent: {
        en: 'The command has been sent to the device',
        ru: 'Команда отправлена на устройство'
      },
      coordsUpdated: {
        en: 'Device position updated',
        ru: 'Положение на карте сохранено'
      },
      requestSent: {
        en: 'Request sent',
        ru: 'Данные обновлены'
      },
      noEndGuns: {
        en: 'End guns are not set for this device',
        ru: 'Концевые пушки для данного устройства не установлены'
      },
      endGunsUpdated: {
        en: 'End guns updated',
        ru: 'Данные по концевым пушкам проставлены'
      },
      hoseStopsUpdated: {
        en: 'Hose stops updated',
        ru: 'Данные hose stops проставлены'
      },
      noCamera: {
        en: 'Camera is not set for this device',
        ru: 'Камера для данного устройства не установлена'
      },
      noReport: {
        en: 'Data not found',
        ru: 'Данные не найдены'
      }
    },
    endGun: {
      title: {
        en: 'End Gun',
        ru: 'Концевая пушка'
      },
      suffix: {
        en: 'm',
        ru: 'м'
      },
      noAreas: {
        en: 'End gun areas are not defined',
        ru: 'Области не заданы'
      }
    },
    hoseStop: {
      title: {
        en: 'Hose Stop',
        ru: 'Гидранты'
      },
      suffix: {
        en: 'm',
        ru: 'м'
      },
    },
    mapLayers: {
      titles: {
        en: 'Titles',
        ru: 'Названия'
      },
      fields: {
        en: 'Fields',
        ru: 'Поля'
      },
    },
    mapProviders: {
      osm: {
        en: 'Open Street Map',
        ru: 'Схема'
      },
      esri: {
        en: 'Esri',
        ru: 'Спутник'
      },
      titles: {
        en: 'Titles',
        ru: 'Названия'
      },
      fields: {
        en: 'Fields',
        ru: 'Поля'
      }
    },
    admin: {
      settings: {
        en: 'Settings',
        ru: 'Настройки'
      },
      deviceSettings: {
        en: 'Admin Device Settings',
        ru: 'Настройки доступа'
      },
      menu: {
        users: {
          en: 'Users',
          ru: 'Пользователи'
        },
        organizations: {
          en: 'Organizations',
          ru: 'Организации'
        }
      },
      users: {
        id: {
          en: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          ru: 'Имя'
        },
        email: {
          en: 'Email',
          ru: 'Электронная почта'
        },
        role: {
          en: 'Role',
          ru: 'Роль'
        },
        organization: {
          en: 'Organization',
          ru: 'Организация'
        },
        devices: {
          en: 'Devices',
          ru: 'Устройства'
        },
        noDataText: {
          en: 'No Users Available',
          ru: 'Список пользователей пуст'
        }
      },
      organizations: {
        id: {
          en: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          ru: 'Название'
        },
        devices: {
          en: 'Devices',
          ru: 'Устройства'
        },
        noDataText: {
          en: 'No Organizations Available',
          ru: 'Список организаций пуст'
        }
      },
      user: {
        title: {
          en: 'User',
          ru: 'Пользователь'
        },
        newTitle: {
          en: 'New User',
          ru: 'Новый пользователь'
        }
      },
      organization: {
        title: {
          en: 'Organization',
          ru: 'Организация'
        },
        newTitle: {
          en: 'New Organization',
          ru: 'Новая организация'
        }
      }
    },
    camera: {
      add: {
        en: 'Add Camera',
        ru: 'Добавить камеру'
      },
      edit: {
        en: 'Edit Camera Settings',
        ru: 'Редактировать настройки камеры'
      }
    },
    pivot: {
      field: {
        en: 'Field',
        ru: 'Параметр'
      },
      value: {
        en: 'Value',
        ru: 'Значение'
      }
    },
    report: {
      pivot: {
        en: 'Pivot',
        ru: 'Пивот'
      },
      organization: {
        en: 'Organization',
        ru: 'Организация'
      },
      date: {
        en: 'Date/Time',
        ru: 'Дата/Время'
      },
      status: {
        en: 'Status',
        ru: 'Статус'
      },
      position: {
        en: 'Position',
        ru: 'Позиция'
      },
      speed: {
        en: 'Speed',
        ru: 'Скорость'
      },
      duration: {
        en: 'Duration',
        ru: 'Длительность'
      }
    },
    backEndStatuses: {
      alg_err: {
        en: 'alg_err',
        ru: 'Ошибка выравнивания'
      },
      prsr_low: {
        en: 'prsr_low',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'rst_dly',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'prsr_wait',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'prsr_rcy',
        ru: 'Восст-е давления'
      },
      prsr_min: {
        en: 'prsr_min',
        ru: 'Мин.допустимое давл.'
      },
      over_water: {
        en: 'Over Water',
        ru: 'Over Water'
      },
      gps_error: {
         en: 'GPS Error',
         ru: 'Ошибка GPS'
      },
    },
    filter: {
      date_from: {
        en: 'Date From',
        ru: 'Дата от',
      },
      date_to: {
        en: 'Date To',
        ru: 'Дата до',
      },
      period: {
        en: 'Period',
        ru: 'Период',
      },
    },
    field_station: {
      en: 'Field Station',
      ru: 'Полевая',
    },
    meteo_station: {
      en: 'Meteo Station',
      ru: 'Метео',
    },
    charts: {
      watermark: {
        tab: {
          en: 'Watermark',
          ru: 'Влажность почвы',
        },
        wet: {
          en: 'Soil Moisture',
          ru: 'Влажность'
        },
        precipitation: {
          en: 'Precipitation',
          ru: 'Осадки'
        }
      },
      units: {
        mm: {
          en: 'mm',
          ru: 'мм'  
        },
        cm: {
          en: 'cm',
          ru: 'см'
        }
      }
    }
  }
}
