var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fc-map__bar"},[_c('div',{staticClass:"hidden-sm-and-down fc-map__menu elevation-1"},[_c('div',{staticClass:"mx-2"},_vm._l((_vm.menu),function(item){return _c('v-btn',{key:item.key,class:{'v-btn--active': _vm.$route.name === item.activeOn},attrs:{"to":item.to,"text":"","large":""}},[_c('img',{staticClass:"fc-map__menu-icon",attrs:{"src":require('~/assets/icons/' + item.icon + '.svg')}}),_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])}),1)]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-md-and-up elevation-1 mx-1",attrs:{"fab":""}},on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(item){return _c('v-list-item',{key:item.key,attrs:{"to":item.to}},[_c('v-list-item-icon',[_c('img',{attrs:{"src":require('~/assets/icons/' + item.icon + '.svg'),"height":"20"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])],1)],1)}),1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-1 mx-1",attrs:{"slot":"activator","fab":""},on:{"click":function($event){return _vm.$bus.$emit('map.center')}},slot:"activator"},on),[_c('v-icon',[_vm._v("mdi-image-filter-center-focus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.centerMap')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-1 mx-1",attrs:{"loading":_vm.loading,"fab":""},on:{"click":function($event){return _vm.$emit('update')}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.refresh')))])]),_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-1 mx-1",attrs:{"fab":""}},on),[_c('v-icon',[_vm._v("mdi-layers-triple")])],1)]}}])},[_c('v-list',[_c('v-list-item',{key:"titles",class:{'v-list-item--active font-weight-bold': !_vm.hideTitles},attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.setHideTitles(!_vm.hideTitles)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":!_vm.hideTitles,"disabled":""}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('mapProviders.titles'))+" ")])],1),_c('v-list-item',{key:"fields",class:{'v-list-item--active font-weight-bold': !_vm.hideFields},attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.setHideFields(!_vm.hideFields)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":!_vm.hideFields,"disabled":""}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('mapProviders.fields'))+" ")])],1),_vm._l((_vm.providerOptions),function(option){return _c('v-list-item',{key:option.id,class:{'v-list-item--active font-weight-bold': _vm.provider === option.id},attrs:{"link":""},on:{"click":function($event){return _vm.setProvider(option.id)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.provider === option.id,"disabled":""}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('mapProviders.' + option.id))+" ")])],1)})],2)],1),_c('user-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-1 mx-1",attrs:{"fab":""}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }