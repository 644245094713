const prepareItem = (item) => {
  item = {
    ...item,
    deviceStatus: {
      ...(item.deviceStatus || {})
    }
  }
  item.fullName = `${item.name} (${item.uuid})`
  item.deviceStatus.real_move = item.deviceStatus.move
  if (item.deviceStatus.move === 'finish') {
    item.deviceStatus.move = 'stop'
  }
  return item
}

export default {
  baseURLS: [
    // 'http://fieldconnect.test/api',
    //  'https://api.fieldconnect.ru/api',
    // 'https://api.leader.fieldconnect.ru/api',
    // 'https://api.demo.fieldconnect.ru/api',
    'https://api.demo1.fieldconnect.ru/api'
  ],
  rtuRequestInterval: 10000,
  rtu: {
    list: {
      get: '/rtu/all',
      prepareItem,
      cache: 0
    },
    cmd: {
      post: '/rtu/:id/cmd'
    },
    updateCoords: {
      put: '/rtu/:id'
    },
    updateEndGuns: {
      post: '/rtu/:deviceId/guns'
    },
    updateHoseStops: {
      post: '/rtu/:deviceId/hose-stops'
    },
    request: {
      main: {
        get: '/rtu/:id/info'
      },
      guns: {
        post: '/rtu/:id/guns/info'
      },
      hoseStop: {
        post: '/rtu/:id/hose-stops/info'
      },
    },
    updateCamera: {
      post: '/rtu/:id/camera'
    },
    updateAdminSettings: {
      put: '/rtu/:id'
    },
    updateSnapshot: {
      post: '/rtu/:id/snapshot/refresh'
    },
    pivotRequest: {
      get: '/rtu/:id/pivot-settings'
    },
    pumpRequest: {
      get: '/rtu/:id/pump-settings'
    },
    reportRequest: {
      get: '/rtu/:id/report'
    }
  },
  auth: {
    me: {
      post: '/auth/me'
    },
    login: {
      post: '/auth/login'
    },
    restorePassword: {
      post: '/auth/reset'
    },
    settings: {
      post: '/auth/personalize'
    }
  },
  admin: {
    users: {
      get: '/user'
    },
    user: {
      create: {
        post: '/user'
      },
      update: {
        put: '/user/:id'
      },
      delete: {
        delete: '/user/:id'
      }
    },
    organization: {
      create: {
        post: '/org'
      },
      update: {
        put: '/org/:id'
      },
      delete: {
        delete: '/org/:id'
      }
    },
    organizations: {
      get: '/org'
    }
  },
  report: {
    post: '/report'
  },
  station: {
    list: {
      get: '/stations',
    },
    request: {
      post: '/station/:id'
    }
  },
  fields: {
    get: '/fields'
  }
}
