<script>
import {polygon} from 'leaflet'

export default {
  props: ['item', 'map'],
  computed: {
    hideTitles () { return this.$store.state.map.hideTitles },
    displaySettings () { 
      return this.item.displaySettings || {} 
    },
    coordinates () {
      return this.displaySettings.coordinates
    },
    label () {
      return this.displaySettings.name
    },
    drawTrigger () {
      return JSON.stringify({
        map: !!this.map,
        coordinates: this.coordinates,
        label: this.label,
        hideTitles: this.hideTitles
      })
    }
  },
  watch: {
    drawTrigger: {
      immediate: true,
      handler () {
        if (this.mapObject) {
          this.mapObject.remove()
        }
        if (!this.map) { 
          return false 
        }
        this.mapObject = polygon(this.coordinates, {color: 'white'})
        this.mapObject.bindTooltip(this.label, {permanent: !this.hideTitles, direction:"center", className: 'labelstyle'})
        this.mapObject.addTo(this.map)
        this.mapObject.bringToBack()
      }
    },
  },
  render () {
    return null
  },
  beforeDestroy() {
    this.mapObject && this.mapObject.remove()
  }
}
</script>
