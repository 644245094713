var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fc-map__page -full-height -outer-corners"},[_c('div',{staticClass:"fc-map__page-body"},[_c('scrollable-table',{staticClass:"fc-map-table",attrs:{"head-size":40},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('div',{staticClass:"fc-map-table__row fc-map-table__row--header"},_vm._l((_vm.columns),function(column){return _c('div',{key:column.key,staticClass:"fc-map-table__cell fc-map-table__cell--header",class:'fc-map-table__cell--' + column.key,style:({
              flex: column.flex
            })},[(column.skipSort)?_c('div',{staticClass:"fc-map-table__header-link"},[_vm._v(" "+_vm._s(_vm.$t('meteosColumns.' + column.key))+" ")]):_c('router-link',{staticClass:"fc-map-table__header-link",attrs:{"to":{
                query: {
                  order: _vm.order === column.key ? column.key + '-desc' : column.key
                }
              }}},[_vm._v(" "+_vm._s(_vm.$t('meteosColumns.' + column.key))+" "),(_vm.order === column.key)?_c('v-icon',{staticClass:"fc-map-table__header-link-icon"},[_vm._v(" mdi-menu-down ")]):(_vm.order === column.key + '-desc')?_c('v-icon',{staticClass:"fc-map-table__header-link-icon"},[_vm._v(" mdi-menu-up ")]):_vm._e()],1)],1)}),0)]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.sortedItems),function(item){return _c('div',{key:item.id,staticClass:"fc-map-table__row"},_vm._l((_vm.columns),function(column){return _c('div',{key:column.key,staticClass:"fc-map-table__cell",class:'fc-map-table__cell--' + column.key,style:({
              flex: column.flex
            })},[(column.key === 'id')?[_c('router-link',{attrs:{"to":{name: 'station', query: {id: item.id}}}},[_vm._v(" "+_vm._s(item.id)+" ")])]:(column.key === 'name')?[_vm._v(" "+_vm._s(item.name)+" ")]:(column.key === 'device_name')?[_vm._v(" "+_vm._s(item.device_name)+" ")]:(column.key === 'type')?[_vm._v(" "+_vm._s(_vm.$t(item.type))+" ")]:(column.key === 'connection')?[_c('item-online',{attrs:{"item":item}})]:_vm._e()],2)}),0)})},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }