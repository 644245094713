export default {
  cookieName: 'fc_settings',
  default: {
    map: {
      center: {lat: 49.9941989381303, lng: 45.63427700772411},
      zoom: 13,
      provider: 'esri',
      hideFields: false,
      hideTitles: false,
    },
  },
  cookieOptions: {
    expires: 7,
    path: ''
  }
}
