export default {
  login: [
    {key: 'email', label: 'form.email', type: 'string'},
    {key: 'password', label: 'form.password', type: 'string', inputOptions: {type: 'password'}}
  ],
  restorePassword: [
    {key: 'email', label: 'form.email', type: 'string'}
  ],
  item: [
    {key: 'deviceSettings.workPlan.speed', type: 'number', label: 'form.speed', inputOptions: {suffix: '%', hideDetails: true}, min: 0, max: 100, cols: 4, decimal: 1},
    {key: 'deviceSettings.workPlan.depth', type: 'number', label: 'form.depth', inputOptions: {suffix: 'form.depthSuffix', hideDetails: true}, default: 0, cols: 4, decimal: 2},
    {key: 'deviceSettings.workPlan.time', type: 'number', label: 'form.time', inputOptions: {suffix: 'form.timeSuffix', hideDetails: true}, default: 0, cols: 4, decimal: 2},
    {key: 'deviceStatus.pressure', label: 'form.pressure', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true, disabled: true, outlined: true}, cols: 6},
    {key: 'deviceStatus.waterConsumption', label: 'form.flow', inputOptions: {suffix: 'form.flowSuffix', hideDetails: true, disabled: true, outlined: true}, cols: 6},
    {key: 'deviceStatus.voltage', label: 'form.voltage', inputOptions: {suffix: 'form.voltageSuffix', hideDetails: true, disabled: true, outlined: true}, cols: 6},
    {key: 'deviceStatus.temperature', label: 'form.temperature', inputOptions: {suffix: 'form.temperatureSuffix', hideDetails: true, disabled: true, outlined: true}, default: 0, cols: 6},
    {key: 'deviceSettings.workPlan.serviceStop', type: 'number', forItemType: 'circle', label: 'form.serviceStop', inputOptions: {suffix: '°', hideDetails: true, placeholder: '0 - 360°'}, min: 0, max: 360, cols: 6, decimal: 1},
    {key: 'deviceSettings.workPlan.serviceStop', type: 'number', forItemType: 'lateral', label: 'form.serviceStop', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, min: 0, cols: 6, decimal: 0},
    {key: 'deviceSettings.workPlan.startAngle', show: () => false, type: 'number', label: 'form.startAngle', inputOptions: {suffix: '°', hideDetails: true, placeholder: '0 - 360°'}, min: 0, max: 360, cols: 6, decimal: 1},
    {key: 'deviceSettings.workPlan.serviceStopRepeat', type: 'boolean', label: 'form.serviceStopRepeat', cols: 6},
    {key: 'deviceSettings.workPlan.comment', type: 'text', label: 'form.comment', inputOptions: {textarea: true, outlined: true, rows: 3, hideDetails: true}, show: () => false}
  ],
  itemMapLateral: [
    {key: 'startLat', type: 'number', label: 'form.startLat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'startLng', type: 'number', label: 'form.startLng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'endLat', type: 'number', label: 'form.endLat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'endLng', type: 'number', label: 'form.endLng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'height', type: 'number', label: 'form.lateralHeight', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'length', type: 'number', label: 'form.lateralLength', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'startPosition', type: 'number', label: 'form.startPosition', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'endPosition', type: 'number', label: 'form.endPosition', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0}
  ],
  itemMapCircle: [
    {key: 'lat', type: 'number', label: 'form.lat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'lng', type: 'number', label: 'form.lng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'radius', type: 'number', label: 'form.radius', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'startAngle', type: 'number', label: 'form.startAngle', inputOptions: {suffix: '°', hideDetails: true}, decimal: 1, allowNegative: true},
    {key: 'endAngle', type: 'number', label: 'form.endAngle', inputOptions: {suffix: '°', hideDetails: true}, decimal: 1}
  ],
  gun: [
    {key: 'status', type: 'boolean', switchOptions: {hideDetails: true, trueValue: 'on', falseValue: 'off', class: 'ma-0 pa-0'}},
    {key: 'startAngle', type: 'number', inputOptions: {suffix: '°', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 360, decimal: 1},
    {key: 'endAngle', type: 'number', inputOptions: {suffix: '°', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 360, decimal: 1},
    {key: 'start', type: 'number', inputOptions: {suffix: 'form.radiusSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 0},
    {key: 'end', type: 'number', inputOptions: {suffix: 'form.radiusSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 0}
  ],
  hoseStop: [
    {key: 'position', type: 'number', inputOptions: {suffix: 'form.hoseStopSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 1000, decimal: 1},
    {key: 'alertDistance', type: 'number', inputOptions: {suffix: 'form.hoseStopSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 1000, decimal: 1}
  ],
  camera: [
    {key: 'ip', label: 'form.camera.ip', type: 'string', cols: 5},
    {key: 'port', label: 'form.camera.port', type: 'string', cols: 5},
    {key: 'status', label: 'form.camera.status', type: 'select', dataSource: ['on', 'off'], cols: 2},
    {key: 'login', label: 'form.camera.login', type: 'string', cols: 6},
    {key: 'password', label: 'form.camera.password', type: 'string', cols: 6},
    {key: 'camera_num', label: 'form.camera.cameraNum', type: 'string', cols: 6}
  ],
  adminItem: [
    {key: 'organization', permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: false}
  ],
  user: [
    {key: 'id', show: () => false},
    {key: 'name', label: 'form.user.name', type: 'string'},
    {key: 'email', label: 'form.user.email', type: 'string'},
    {key: 'password', label: 'form.user.password', type: 'string', show: (instance) => !instance.id },
    {key: 'role', label: 'form.user.role', type: 'select', dataSource: ['SuperAdmin', 'Admin', 'User', 'ReadOnly']},
    {key: 'organizations', saveCommaMode: true, saveAs: ['organization', 'organizations'], permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true},
    {key: 'all_devices', label: 'form.user.allDevices', type: 'boolean'},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], show: (instance) => !instance.all_devices, label: 'form.user.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true}
  ],
  organization: [
    {key: 'id', show: () => false},
    {key: 'name', label: 'form.organization.name',type: 'string'},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], label: 'form.organization.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true},
    {key: 'ip', label: 'form.organization.proxy.ip', type: 'string', cols: 5},
    {key: 'port', label: 'form.organization.proxy.port', type: 'string', cols: 5},
  ],
  reportFilter: [
    {key: 'from', label: 'filter.date_from', type: 'date', cols: 2},
    {key: 'to', label: 'filter.date_to', type: 'date', cols: 2},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], label: 'form.organization.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true, cols: 3},
    {key: 'organizations', saveCommaMode: true, saveAs: ['organization', 'organizations'], permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true, cols: 2, show: (instance) => true},
    {key: 'status', saveCommaMode: true, saveAs: ['status'], label: 'report.status', type: 'select', dataSource: ['Движение вперед', 'Движение вперед с водой', 'Движение назад', 'Движение назад с водой', 'Движение прекращено', 'Движение прекращено с ошибкой', 'Остановка для обслуживания'], selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true, cols: 3, show: (instance) => true},
  ],
  stationFilter: [
    {key: 'from', label: 'filter.date_from', type: 'date', cols: 4},
    {key: 'to', label: 'filter.date_to', type: 'date', cols: 4},
    {key: 'period', saveCommaMode: true, saveAs: ['period'], label: 'filter.period', type: 'select', dataSource: ['Час', 'День'], cols: 4},
  ]
}
