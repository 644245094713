import configMapProviders from '~/config/mapProviders'

export const state = () => ({
  center: null,
  zoom: null,
  provider: null,
  hideFields: false,
  hideTitles: false,
})

export const getters = {
  provider (state) {
    return configMapProviders.find(providerData => providerData.id === state.provider) || configMapProviders[0]
  }
}

export const mutations = {
  setCenter (state, center) {
    state.center = center
  },
  setZoom (state, zoom) {
    state.zoom = zoom
  },
  setProvider (state, provider) {
    state.provider = provider
  },
  setHideFields (state, hideFields) {
    state.hideFields = hideFields
  },
  setHideTitles (state, hideTitles) {
    state.hideTitles = hideTitles
  }
}

export const actions = {
  setCenter ({commit, dispatch}, center) {
    commit('setCenter', center)
  },
  setZoom ({commit, dispatch}, zoom) {
    commit('setZoom', zoom)
  },
  setProvider ({commit, dispatch}, provider) {
    commit('setProvider', provider)
    return dispatch('ux/updateSettings', {map: {provider}}, {root: true})
  },
  setHideFields ({commit, dispatch}, hideFields) {
    commit('setHideFields', hideFields)
    return dispatch('ux/updateSettings', {map: {hideFields}}, {root:true})
  },
  setHideTitles ({commit, dispatch}, hideTitles) {
    commit('setHideTitles', hideTitles)
    return dispatch('ux/updateSettings', {map: {hideTitles}}, {root:true})
  },
  restoreFromSettings ({commit}, data) {
    if (data.center) { commit('setCenter', data.center) }
    if (data.zoom) { commit('setZoom', data.zoom) }
    if (data.provider) { commit('setProvider', data.provider) }
    if (data.hideFields) { commit('setHideFields', data.hideFields) }
    if (data.hideTitles) { commit('setHideTitles', data.hideTitles) }
    return Promise.resolve()
  }
}
