<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <div class="d-flex flex-wrap justify-end mt-3">
        <!-- <v-btn :loading="loading" text class="ml-3" @click="request('report')">
          {{ $t('actions.requestReport') }}
        </v-btn> -->
      </div>
      <v-alert v-if="!report.length" type="info" outlined>
        {{ $t('success.noReport') }}
      </v-alert>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th>{{ $t('report.date') }}</th>
            <th>{{ $t('report.status') }}</th>
            <th>{{ $t('report.position') }}</th>
            <th>{{ $t('report.speed') }}</th>
            <th>{{ $t('report.duration') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in report" :key="row.key">
            <td>{{ row.date }}</td>
            <td>{{ row.status }}</td>
            <td>{{ row.position }}&#176;</td>
            <td>{{ row.speed }}%</td>
            <td>{{ row.duration }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  props: ['id', 'tab'],
  mixins: [generalFormMixin],
  computed: {
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    report() {
      const out = []
      if (this.item && this.item.report) {
          return this.item.report
      }
      return out
    }
  },
  methods: {
    request (target) {
      this.setLoading(true)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: `rtu.${target}Request`, data: {id: this.id}}))
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t(`success.${target}Requested`)})
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
