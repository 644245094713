import configApi from '~/config/api'
import clientStorage from '~/clientStorage'

export const state = () => ({
  user: null,
  snapshotToken: null
})

export const getters = {
  isLoggedIn (state) { return !!(state.user || {}).email },
  isAdmin (state) { return ((state.user || {}).role || '').toLowerCase().includes('admin') }
}

export const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setSnapshotToken (state, token) {
    state.snapshotToken = token
  }
}

export const actions = {
  fetch ({commit, dispatch}, token) {
    let fetchResponse = null
    return Promise.resolve()
      .then(() => dispatch('fetch/setToken', token, {root: true}))
      .then(() => dispatch('fetch/fetch', {path: 'auth.me'}, {root: true}))
      .then((response) => {
        fetchResponse = response
        clientStorage.set('auth_token', token)
        commit('setUser', response.data)
        const promises = []
        promises.push(dispatch('ux/loadServerSettings', response.data, {root: true}))
        if (response.data.snapshot_token) {
          promises.push(dispatch('setSnapshotToken', response.data.snapshot_token))
        }
        return Promise.all(promises)
      })
      .then(() => fetchResponse)
      .catch((error) => {
        return Promise.resolve()
          .then(() => dispatch('fetch/setToken', null, {root: true}))
          .then(() => {
            clientStorage.remove('auth_token')
            commit('setUser', null)
            throw error
          })
      })
  },
  login ({dispatch}, data) {
    // Iterate over baseURLS to find the correct api. Fail only if no api was found.
    return new Promise((resolve, reject) => {
      let latestError = null
      const run = (apiIndex) => {
        if (apiIndex >= configApi.baseURLS.length) {
          reject(latestError)
        } else {
          Promise.resolve()
            .then(() => dispatch('fetch/setAxiosDefaults', {baseURL: configApi.baseURLS[apiIndex]}, {root: true}))
            .then(() => dispatch('fetch/fetch', {path: 'auth.login', data}, {root: true}))
            .then((response) => {
              if (response && response.data.access_token) {
                return Promise.resolve()
                  .then(() => dispatch('setSnapshotToken', response.data.snapshot_token))
                  .then(() => {
                    clientStorage.set('api_base_url', configApi.baseURLS[apiIndex])
                  })
                  .then(() => dispatch('fetch', response.data.access_token))
                  .then(resolve)
              } else {
                throw new Error('Token not found in auth.login response.')
              }
            })
            .catch((error) => {
              latestError = error
              run(apiIndex + 1)
            })
        }
      } 
      run(0)         
    })
  },
  restorePassword ({dispatch}, data) {
    // Iterate over baseURLS to find the correct api. Fail only if all the apis return error.
    return new Promise((resolve, reject) => {
      let latestError = null
      const run = (apiIndex) => {
        if (apiIndex >= configApi.baseURLS.length) {
          reject(latestError)
        } else {
          Promise.resolve()
            .then(() => dispatch('fetch/setAxiosDefaults', {baseURL: configApi.baseURLS[apiIndex]}, {root: true}))
            .then(() => dispatch('fetch/fetch', {path: 'auth.restorePassword', data}, {root: true}))
            .then(resolve)
            .catch((error) => {
              latestError = error
              run(apiIndex + 1)
            })
        }
      }
      run(0)
    })
  },
  logout ({commit, dispatch}, data) {
    clientStorage.remove('auth_token')
    clientStorage.remove('api_base_url')
    commit('setUser', null)
    return dispatch('ux/clearSettings', null, {root: true})
  },
  setSnapshotToken ({commit}, token) {
    clientStorage.set('snapshot_token', token)
    commit('setSnapshotToken', token)
  }
}
