<template>
  <div class="fc-map__page -full-height -outer-corners">
    <div class="fc-map__page-body">
      <scrollable-table
        :head-size="40"
        class="fc-map-table"
      >
        <template #head>
          <div class="fc-map-table__row fc-map-table__row--header">
            <div
              v-for="column in columns"
              :key="column.key"
              :style="{
                flex: column.flex
              }"
              :class="'fc-map-table__cell--' + column.key"
              class="fc-map-table__cell fc-map-table__cell--header"
            >
              <div
                v-if="column.skipSort"
                class="fc-map-table__header-link"
              >
                {{ $t('meteosColumns.' + column.key) }}
              </div>
              <router-link
                v-else
                :to="{
                  query: {
                    order: order === column.key ? column.key + '-desc' : column.key
                  }
                }"
                class="fc-map-table__header-link"
              >
                {{ $t('meteosColumns.' + column.key) }}
                <v-icon
                  v-if="order === column.key"
                  class="fc-map-table__header-link-icon"
                >
                  mdi-menu-down
                </v-icon>
                <v-icon
                  v-else-if="order === column.key + '-desc'"
                  class="fc-map-table__header-link-icon"
                >
                  mdi-menu-up
                </v-icon>
              </router-link>
            </div>
          </div>
        </template>
        <template #body>
          <div
            v-for="item in sortedItems"
            :key="item.id"
            class="fc-map-table__row"
          >
            <div
              v-for="column in columns"
              :key="column.key"
              :style="{
                flex: column.flex
              }"
              :class="'fc-map-table__cell--' + column.key"
              class="fc-map-table__cell"
            >
              <template v-if="column.key === 'id'">
                <router-link :to="{name: 'station', query: {id: item.id}}">
                  {{ item.id }}
                </router-link>
              </template>
              <template v-else-if="column.key === 'name'">
                {{ item.name }}
              </template>
              <template v-else-if="column.key === 'device_name'">
                {{ item.device_name }}
              </template>
              <template v-else-if="column.key === 'type'">
                {{ $t(item.type) }}
              </template>
              <template v-else-if="column.key === 'connection'">
                <item-online :item="item" />
              </template>
            </div>
          </div>
        </template>

      </scrollable-table>
    </div>

  </div>
</template>

<script>

import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import ScrollableTable from "@/components/utils/ScrollableTable";
import ItemOnline from "@/components/map/ItemOnline";

export default {
  components: {ItemOnline, ScrollableTable},
  mixins: [dateTimeFormat, generalFormMixin],
  data () {
    return {
      columns: [
        {key: 'id', flex: '0 0 150px'},
        {key: 'name', flex: '0 0 180px'},
        {key: 'device_name', flex: '0 0 150px'},
        {key: 'type', flex: '0 0 150px'},
        {key: 'connection', flex: '0 0 70px'},
      ],
      instance: {},
    }
  },
  computed: {
    deviceStatus () { return this.item.deviceStatus || {} },
    items () { return this.$store.getters['station/items'] },
    order () { return this.$route.query.order || 'equipment' },
    sortedItems () {
      return [...this.items]
    }
  },
  watch: {
    instanceTrigger: {
      immediate: true,
      handler () {
        const instance = {}
        const loading = {}


        this.instance = instance
        this.loading = loading
      }
    }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {

  }
}
</script>
