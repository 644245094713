<template>
  <div class="fc-map__page-body"  style="overflow: unset">
    <div class="body-1">
      <v-card-text>
        <v-row>
          <v-col
              cols="10"
          >
            <fields v-model="instance" :fields="fields" />
          </v-col>
          <v-col
              cols="2"
          >
            <v-card-actions class="px-6 pb-4">
              <v-spacer />
              <v-btn :loading="loading" color="primary" @click="find">{{ $t('actions.find') }}</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <div class="fc-map__page-body pb-3">
      <Chart :options="chartOptions2" v-if="showChart2"></Chart>
      <Chart :options="chartOptions1"></Chart>
    </div>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import {Chart} from 'highcharts-vue'

export default {
  components: {Chart},
  mixins: [generalFormMixin],
  props: ['id', 'tab'],
  data() {
    return {
      loading: false,
      chartData: [],
      chartOptions1: {},
      chartOptions2: {},
      instance: {
        from: null,
        to: null
      },
    }
  },
  computed: {
    count () { return this.chartData.dates.length },
    maxValue () {
      return Math.max(...$.map(this.chartData['Watermarks'], val => val))
    },
    minValue () {
      return Math.min(...$.map(this.chartData['Watermarks'], val => val))
    },
    yAxisMax () {
      return this.chartData['prov'] === 2 ? (this.maxValue + this.maxValue * 0.05) : 100
    },
    yAxisMin () {
      return this.chartData['prov'] === 2 ? (this.minValue - this.maxValue * 0.05) : 0
    },
    fields () { return configFields.stationFilter },
    showChart1 () {
      return !_.isEmpty(this.chartData['Precipitation'])
    },
    showChart2 () {
      return !_.isEmpty(this.chartData['Watermarks'])
    },
    reversed () {
      return this.chartData['prov'] === 1 ? true : false
    },
    provider () {
      return this.chartData['prov']
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.loadData()
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    setLoading (value) {
      this.loading = value
    },
    centerItem () {
      if (this.item && this.item.displaySettings && this.item.displaySettings.type === 'station' && this.item.displaySettings.center) {
        this.$bus.$emit('map.center', this.item.displaySettings.center)
      }
    },
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'station.request', data: {id: this.id, data: data}}))
          .then((response) => {
            this.chartData = response.data
            this.chart()
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })

    },
    loadData (force) {
      return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'station.request', data: {id: this.id}, force}))
          .then((response) => {
            this.chartData = response.data
            this.instance.from = moment(this.chartData.dates[0] + '.2021', 'DD.MM.YYYY').format('YYYY-MM-DD')
            this.instance.to = moment(this.chartData.dates[this.count - 1] + '.2021', 'DD.MM.YYYY').format('YYYY-MM-DD')
            this.chart()

            console.log(this.minValue)
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })
    },
    chart () {
      this.chartOptions1 = {
        chart: {
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 250,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.chartData.dates,
          // height: 101,
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          // height: 100,
          // top: 60,
          tickInterval: 5,
          min: 0,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.watermark.precipitation') + ', ' + this.$t('charts.units.mm'),
            margin: 15
          },
          labels: {
            enabled: true
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0) {
                  return this.y;
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          enabled: false
        },
        series: {
          yAxis: 0,
          zIndex: 5,
          events:{
            legendItemClick: function(){
              return false;
            }
          },
          data: this.chartData['Precipitation'],
          enableMouseTracking: false,
          type: 'column',
          lineColor: '#4670bd',
          color: '#4670bd',
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            enabled: true
          }
        }
      }
      
      if (this.chartData['Watermarks'] !== undefined) {
        this.chartOptions2 = {
          chart: {
            type: '',
            spacingLeft: 0,
            alignTicks: false,
            marginTop: 50,
            height: 250
          },
          credits: {
            enabled: false
          },
          title: {
            text: null,
          },
          subtitle: {
            text: null,
          },
          xAxis: {
            categories: this.chartData.dates,
            labels: {
              rotation: -45,
            }
          },
          yAxis: [{
            max: this.yAxisMax,
            min: this.yAxisMin,
            tickInterval: 20,
            startOnTick: false,
            endOnTick: false,
            title: {
              text: this.$t('charts.watermark.wet') + ', %',
              margin: 15
            },
            labels: {
              enabled: false
            },
            gridLineWidth: 0.5,
            reversed: this.reversed,
          }],
          plotOptions: {
            series: {
              animation: false,
              pointWidth: 0,
              marker: {
                symbol: 'circle',
                enabled: false
              },
            },
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              return this.provider === 1 ? (100 - this.y).toFixed(1) : this.y
            }
          },
          legend: {
            enabled: true
          },
          series: []                      
        }

        // this.chartOptions2.series.push({
        //       yAxis:0,
        //       zIndex: 1,
        //       type: 'areaspline',
        //       fillColor: '#FFB2B2',
        //       lineWidth: 0,
        //       data: Array(this.count).fill(100),
        //       enableMouseTracking: false,
        //       showInLegend: false,
        //     })

        if (this.provider === 1) {

          this.chartOptions2.series.push(
              // Orange
              {
                yAxis: 0,
                zIndex: 2,
                type: 'areaspline',
                fillColor: 'rgba(255,228,186)',
                lineWidth: 0,
                data: Array(this.count).fill(100),
                enableMouseTracking: false,
                showInLegend: false,
                marker: {
                  enabled: false
                },
              },
              // Green
              {
                yAxis: 0,
                zIndex: 3,
                type: 'areaspline',
                fillColor: 'rgba(178,217,191)',
                lineWidth: 0,
                data: Array(this.count).fill(90),
                enableMouseTracking: false,
                showInLegend: false,
                marker: {
                  enabled: false
                },
              },
              // Blue
              {
                yAxis: 0,
                zIndex: 4,
                type: 'areaspline',
                fillColor: 'rgb(85,185,231)',
                lineWidth: 0,
                data: Array(this.count).fill(7),
                enableMouseTracking: false,
                showInLegend: false,
                marker: {
                  enabled: false
                },
              }
            )

        }

        if (this.provider === 2) {

          this.chartOptions2.series.push(
              // Blue
              {
                yAxis: 0,
                zIndex: 2,
                type: 'areaspline',
                fillColor: 'rgb(85,185,231)',
                lineWidth: 0,
                data: Array(this.count).fill(this.maxValue + this.maxValue * 0.05),
                enableMouseTracking: false,
                showInLegend: false,
                marker: {
                  enabled: false
                },
              },
              // Green
              {
                yAxis: 0,
                zIndex: 3,
                type: 'areaspline',
                fillColor: 'rgba(178,217,191)',
                lineWidth: 0,
                data: Array(this.count).fill(this.maxValue - this.maxValue * 0.05),
                enableMouseTracking: false,
                showInLegend: false,
                marker: {
                  enabled: false
                },
              },
              // Orange
              {
                yAxis: 0,
                zIndex: 4,
                type: 'areaspline',
                fillColor: 'rgba(255,228,186)',
                lineWidth: 0,
                data: Array(this.count).fill(this.minValue + this.maxValue * 0.02),
                enableMouseTracking: false,
                showInLegend: false,
                marker: {
                  enabled: false
                },
              }
            )

        }
        

        if (this.chartData['Watermarks'][10] !== undefined) {
          this.chartOptions2.series.push(
              // 10cm
              {
                yAxis: 0,
                zIndex: 5,
                // events:{
                //   legendItemClick: function(){
                //     return false;
                //   }
                // },
                data: this.chartData['Watermarks'][10],
                // enableMouseTracking: false,
                type: 'spline',
                lineColor: '#4670bd',
                color: '#4670bd',
                lineWidth: 1,
                name: '10 ' + this.$t('charts.units.cm'),
                marker: {
                  symbol: 'circle',
                  enabled: true
                }
              }
            )
        }

        if (this.chartData['Watermarks'][30] !== undefined) {
          this.chartOptions2.series.push(
              // 30cm
              {
                yAxis: 0,
                zIndex: 5,
                // events:{
                //   legendItemClick: function(){
                //     return false;
                //   }
                // },
                data: this.chartData['Watermarks'][30],
                // enableMouseTracking: false,
                type: 'spline',
                lineColor: '#9f46bd',
                color: '#9f46bd',
                lineWidth: 1,
                name: '30 ' + this.$t('charts.units.cm'),
                marker: {
                  symbol: 'circle',
                  enabled: true
                }
              }
            )
          }

        if (this.chartData['Watermarks'][50] !== undefined) {
          this.chartOptions2.series.push(
              // 50cm
              {
                yAxis: 0,
                zIndex: 5,
                // events:{
                //   legendItemClick: function(){
                //     return false;
                //   }
                // },
                data: this.chartData['Watermarks'][50],
                // enableMouseTracking: false,
                type: 'spline',
                lineColor: '#ff9300',
                color: '#ff9300',
                lineWidth: 1,
                name: '50 ' + this.$t('charts.units.cm'),
                marker: {
                  symbol: 'circle',
                  enabled: true
                }
              }
            )
        }
      }
    }
  }
}
</script>