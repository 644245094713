<template>
  <div class="fc-map__page-body">
    <div class="body-1">
      <v-row>
        <v-col cols="12" sm="5">
          <map-item :item="mapItem" :icon-mode="{size: 260, lineWidth: 1}" class="fc-map__map-item" />
          <v-row class="justify-space-between">
            <v-col cols="auto">
              <div class="mb-3">
                {{ timeUntilServiceStop }}
              </div>
              <div>
                <span v-for="(gun, index) in (item.deviceSettings.endGuns || [])" :key="gun.id" class="fc-gun-icon mr-3">
                  <img :src="require('~/assets/icons/icon-gun-' + (gun.status === 'on' ? 'on' : 'off') + '.svg')" width="20">
                  <span class="fc-gun-icon__number">
                    {{ index + 1}}
                  </span>
                </span>
              </div>
            </v-col>
            <v-col v-if="type === 'lateral'" cols="auto">
              {{ item.deviceStatus.currentPosition}} {{ $t('form.radiusSuffix') }}
            </v-col>
            <v-col v-else cols="auto">
              <img :src="require('~/assets/icons/icon-angle.svg')" width="20">
              {{ item.deviceStatus.currentAngle }}°
            </v-col>
          </v-row>
          <div class="caption grey--text">
            {{ item.manufacturer }}
          </div>
          <div v-if="item.organization && item.organization.name" class="caption grey--text">
            {{ item.organization.name }}
          </div>
        </v-col>
        <v-col cols="12" sm="7">
          <!--
          <item-alert v-if="item && item.deviceStatus && (item.deviceStatus.error || item.deviceStatus.warning)" :item="item" mode="title" class="mt-3 mb-0" />
          -->
          <div class="title">
            {{ $t('status.' + itemStatus.message) }}
          </div>
          <div>
            {{ capitalize(formatDateTimeFromNow(item.updated_at.date)) }}
            ({{ formatDateTime(item.updated_at.date) }})
          </div>
          <div>
            {{ $t('workPlan.' + item.deviceSettings.workPlan.type) }}
          </div>
          <div>
            <fields v-model="instance" :fields="fields" :disabled="loading" @updateField="updateInstanceField" />
          </div>
          <item-icon-controls v-model="instance" :disabled="loading" col-class="d-flex justify-space-between align-center" />
        </v-col>
        <v-col cols="12">
          <field-control v-model="instance['deviceSettings.workPlan.comment']" :field="commentField" :disabled="loading" />
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap justify-end">
        <v-btn v-if="!editingInstance" :loading="loading" class="mr-3" text @click="request">{{ $t('actions.request') }}</v-btn>
        <v-btn v-if="editingInstance" :loading="loading" class="mr-3" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
        <v-btn :loading="loading" :disabled="!editingInstance" color="primary" @click="save">{{ $t('actions.apply') }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MapItem from '~/components/map/MapItem'
import ItemIconControls from '~/components/map/ItemIconControls'
import configFields from '~/config/fields'
import configControls from '~/config/controls'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import {capitalize} from '~/utils/time'
import {getItemStatus} from '~/utils/status'

export default {
  components: {MapItem, ItemIconControls},
  mixins: [generalFormMixin, dateTimeFormat],
  props: ['id', 'tab'],
  data () {
    return {
      instance: {},
      editingInstance: false
    }
  },
  computed: {
    type () { return this.item?.displaySettings?.type || 'circle' },
    fields () { return configFields.item.filter(field => !field.forItemType || field.forItemType === this.type) },
    commentField () { return configFields.item.find(field => field.key === 'deviceSettings.workPlan.comment') },
    controls () { return configControls },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    itemStatus () { return getItemStatus(this.item) },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item, editingInstance: this.editingInstance})
    },
    resetEditingInstanceTrigger () {
      return this.id + this.tab
    },
    setEditingInstanceTrigger () {
      return JSON.stringify({instance: this.instance, item: this.item})
    },
    fullCircleTime () {
      return Math.floor(this.item.deviceSettings.fullCircleTime / 60 * 10) / 10
    },
    timeUntilServiceStop () {
      if (this.instance.move === 'stop' || isNaN(Number(this.instance['deviceSettings.workPlan.serviceStop']) || isNaN(Number(this.item.deviceSettings.fullCircleTime))) || isNaN(Number(this.item.deviceStatus.currentAngle))) {
        return '---'
      }
      let angleDiff = (this.instance.move === 'frw' ? -1 : 1) * (this.item.deviceStatus.currentAngle - (this.instance['deviceSettings.workPlan.serviceStop'] !== null ? this.instance['deviceSettings.workPlan.serviceStop'] : this.instance['deviceSettings.workPlan.startAngle']))
      while (angleDiff < 0) { angleDiff += 360 }
      while (angleDiff > 360) { angleDiff -= 360 }
      let timeMin = Math.floor(this.item.deviceSettings.fullCircleTime * angleDiff / 360 * 100 / (this.instance['deviceSettings.workPlan.speed'] || 1))
      const timeH = Math.floor(timeMin / 60)
      timeMin -= timeH * 60
      let out = []
      if (timeH) {
        out.push(timeH + ' ' + this.$t('form.timeSuffixHour'))
      }
      if (timeMin) {
        out.push(timeMin + ' ' + this.$t('form.timeSuffixMinute'))
      }
      return out.join(' ')
    },
    mapItem () {
      const data = ((this.prepareFormSubmitData(this.instance, this.fields) || {}).deviceSettings || {}).workPlan || {}
      this.controls.forEach((control) => {
        data[control.key] = this.instance[control.key]
      })
      return {
        ...this.item,
        deviceStatus: {
          ...this.item.deviceStatus,
          serviceStop: this.instance['deviceSettings.workPlan.serviceStop'],
          move: this.instance.move
        }
      }
    }
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler () {
        if (this.editingInstance) { return false }
        this.instance = this.preparePrimaryInstance(this.item)
      }
    },
    resetEditingInstanceTrigger: 'cancel',
    setEditingInstanceTrigger () {
      const readonlyFieldsRev = this.fields.reduce((out, field) => {
        if (field.disabled || (field.inputOptions || {}).disabled) {
          out[field.key] = true
        }
        return out
      }, {})
      const basicInstance = this.preparePrimaryInstance(this.item)
      Object.keys(this.instance).forEach((key) => {
        if (!readonlyFieldsRev[key] && (this.instance[key] !== basicInstance[key])) {
          console.log('setEditingInstance', key, this.instance[key], basicInstance[key])
        }
      })
      this.editingInstance = !!Object.keys(this.instance).find((key) => {
        return !readonlyFieldsRev[key] && (this.instance[key] !== basicInstance[key])
      })
    }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    capitalize,
    preparePrimaryInstance (item) {
      if (item) {
        const controlFields = this.controls.reduce((out, control) => {
          out[control.key] = item.deviceSettings.workPlan[control.key]
          return out
        }, {})
        const out = {
          ...this.prepareInstance(item, this.fields, true),
          ...controlFields
        }
        const fullCircleTime = item.deviceSettings.fullCircleTime / 60
        out['deviceSettings.workPlan.time'] = Math.floor(fullCircleTime / (out['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        return out
      } else {
        return {}
      }
    },
    updateInstanceField ({key, value}) {
      this.$nextTick(() => {
        const k = this.item.deviceStatus.depth_k
        const fullCircleTime = this.item.deviceSettings.fullCircleTime / 60
        if (key === 'deviceSettings.workPlan.speed') {
          this.instance['deviceSettings.workPlan.depth'] = Math.floor(k / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
          this.instance['deviceSettings.workPlan.time'] = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        } else if (key === 'deviceSettings.workPlan.depth') {
          this.instance['deviceSettings.workPlan.speed'] = Math.floor(k / (this.instance['deviceSettings.workPlan.depth'] || 1) * 10000) / 100
          this.instance['deviceSettings.workPlan.time'] = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        } else if (key === 'deviceSettings.workPlan.time') {
          this.instance['deviceSettings.workPlan.speed'] = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.time'] || 1) * 10000) / 100
          this.instance['deviceSettings.workPlan.depth'] = Math.floor(k / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        }
      })
    },
    cancel () {
      this.instance = this.preparePrimaryInstance(this.item)
    },
    save () {
      this.setLoading(true)
      const data = ((this.prepareFormSubmitData(this.instance, this.fields) || {}).deviceSettings || {}).workPlan || {}
      this.controls.forEach((control) => {
        data[control.key] = this.instance[control.key]
      })
      const command = this.prepareCommand(data)
      command.id = this.item.id
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.cmd', data: command}))
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error)
          }
        })
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 5000)
        }))
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.cmdSent')})
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    prepareCommand (data) {
      const command = {}
      command.move = data.move
      command.speed = parseFloat(data.speed).toFixed(1)
      command.depth = parseFloat(data.depth).toFixed(2)
      command.irrigation = data.irrigation === 'off' ? 'stop' : 'start'
      command.acc = data.acc === 'off' ? 'stop' : 'start'
      command.autorev = data.autoRev === 'off' ? 'stop' : 'start'
      command.servicestop = data.serviceStop
      command.servicestoprepeat = !!data.serviceStopRepeat
      command.autostart = data.autoStart === 'off' ? 'stop' : 'start'
      command.comment = data.comment
      return command
    },
    request () {
      this.setLoading(true)
      Promise.resolve()
        .then(() => new Promise((resolve, reject) => {
          this.$emit('request', {
            type: 'main',
            callback: resolve
          })
        }))
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
