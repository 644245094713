<template>
  <div class="fc-map__bar">
    <div class="hidden-sm-and-down fc-map__menu elevation-1">
      <div class="mx-2">
        <v-btn v-for="item in menu" :key="item.key" :to="item.to" :class="{'v-btn--active': $route.name === item.activeOn}" text large>
          <img :src="require('~/assets/icons/' + item.icon + '.svg')" class="fc-map__menu-icon">
          <span>{{ $t(item.name) }}</span>
        </v-btn>
      </div>
    </div>
    <v-menu>
      <template v-slot:activator="{on}">
        <v-btn fab class="hidden-md-and-up elevation-1 mx-1" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="item in menu" :key="item.key" :to="item.to">
          <v-list-item-icon>
            <img :src="require('~/assets/icons/' + item.icon + '.svg')" height="20">
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.name) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer />
    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn slot="activator" fab class="elevation-1 mx-1" v-on="on" @click="$bus.$emit('map.center')">
          <v-icon>mdi-image-filter-center-focus</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('actions.centerMap') }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn :loading="loading" fab class="elevation-1 mx-1" v-on="on" @click="$emit('update')">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('actions.refresh') }}</span>
    </v-tooltip>
    <v-menu left offset-y>
      <template v-slot:activator="{on}">
        <v-btn fab v-on="on" class="elevation-1 mx-1">
          <v-icon>mdi-layers-triple</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          key="titles"
          :class="{'v-list-item--active font-weight-bold': !hideTitles}"
          link
          @click.stop="setHideTitles(!hideTitles)"
        >
          <v-list-item-action>
            <v-checkbox :input-value="!hideTitles" disabled />
          </v-list-item-action>
          <v-list-item-content>
            {{ $t('mapProviders.titles') }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          key="fields"
          :class="{'v-list-item--active font-weight-bold': !hideFields}"
          link
          @click.stop="setHideFields(!hideFields)"
        >
          <v-list-item-action>
            <v-checkbox :input-value="!hideFields" disabled />
          </v-list-item-action>
          <v-list-item-content>
            {{ $t('mapProviders.fields') }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="option in providerOptions"
          :key="option.id"
          :class="{'v-list-item--active font-weight-bold': provider === option.id}"
          link
          @click="setProvider(option.id)"
        >
          <v-list-item-action>
            <v-checkbox :input-value="provider === option.id" disabled />
          </v-list-item-action>
          <v-list-item-content>
            {{ $t('mapProviders.' + option.id) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <user-menu>
      <template v-slot:activator="{on}">
        <v-btn fab v-on="on" class="elevation-1 mx-1">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
    </user-menu>
  </div>
</template>

<script>
import UserMenu from '~/components/user/UserMenu'
import configMapProviders from '~/config/mapProviders'

export default {
  components: {UserMenu},
  props: ['loading'],
  data () {
    var menu = {
      menu: [
        {key: 'main', activeOn: 'main', to: '/', icon: 'icon-menu-map', name: 'menu.map'},
        {key: 'irrigation', to: '/irrigation', icon: 'icon-menu-irrigation', name: 'menu.irrigation'},
      ]
    }

    // if (this.$store.getters['auth/isAdmin']) {
      menu.menu.push({key: 'meteos', to: '/meteos', icon: 'icon-menu-weather', name: 'menu.meteos'})
    // }

    menu.menu.push({key: 'reports', to: '/reports', icon: 'icon-menu-reports', name: 'menu.reports'})

    return menu
  },
  computed: {
    provider () { return this.$store.state.map.provider },
    hideFields () { return this.$store.state.map.hideFields },
    hideTitles () { return this.$store.state.map.hideTitles },
    providerOptions () { return configMapProviders }
  },
  methods: {
    setProvider (provider) {
      this.$store.dispatch('map/setProvider', provider)
    },
    setHideFields (hideFields) {
      this.$store.dispatch('map/setHideFields', hideFields)
    },
    setHideTitles (hideTitles) {
      this.$store.dispatch('map/setHideTitles', hideTitles)
    }
  }
}
</script>
