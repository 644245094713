<template>
  <div class="fc-map__page -full-height">
    <div class="fc-map__page-header">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-btn icon @click="selectNextItem(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu offset-y min-width="100" max-height="200">
            <template v-slot:activator="{on}">
              <v-btn text v-on="on">
                <h1 class="title">
                  {{ item.name }}
                </h1>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="listItem in items"
                  :key="listItem.id"
                  :class="{'v-item--active v-list-item--active': listItem.id === id}"
                  @click="selectItem(listItem)"
              >
                <v-list-item-title>
                  {{ listItem.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="selectNextItem(+1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <div cols="auto">
          <div class="d-flex align-center">
            <v-btn icon @click="centerItem">
              <v-icon>mdi-image-filter-center-focus</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-tabs
        :value="tab"
        grow
        dark
        show-arrows
        background-color="grey darken-2"
        class="fc-map__page-tabs-container"
        @change="setTab"
    >
      <v-tabs-slider />
      <v-tab v-for="tab in tabs" :key="tab">
        <span class="hidden-xs-only">
          {{ tab }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-rotate-left
        </v-icon>
      </v-tab>

      <v-tab-item>
        <chart-watermark :id="id" :tab="tab"/>
      </v-tab-item>
    </v-tabs>

    <div class="fc-map__page-footer" />
  </div>
</template>

<script>
// import ChartPrecipitation from '~/components/chart/Precipitation'
import ChartWatermark from '~/components/chart/Watermark'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  components: { ChartWatermark},
  mixins: [generalFormMixin],
  data() {
    return {
      loading: false,
      chartData: [],
      // chartOptions: {},
      instance: {},
    }
  },
  computed: {
    id () { return this.$route.query.id },
    tab () { return Number(this.$route.query.tab) || 0 },
    tabs () { return [this.$t('charts.watermark.tab')]},
    items () { return this.$store.getters['station/items'] },
    item () { return this.items.find(item => item.id === this.id) },
  },
  watch: {
    id (value) {
      if (!value) {
        this.$bus.$emit('map.center')
      } else {
        this.centerItem()
      }
    }
  },
  mounted () {
    if (this.id) {
      this.centerItem()
    } else {
      this.$bus.$emit('map.center')
    }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'station.request', data: {id: this.id, data: data}}))
          .then((response) => {
            this.chartData = response.data
            this.chart()
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })

    },
    selectItem (item) {
      this.$router.push({name: 'station', query: {id: item.id, tab: this.tab}})
    },
    setTab (tab) {
      this.$router.push({name: 'station', query: {id: this.id, tab}})
    },
    selectNextItem (direction) {
      if (!this.id || !this.items.length) {
        return false
      }
      let itemIndex = this.items.findIndex(item => item.id === this.id)
      itemIndex = (itemIndex || 0) + direction
      while (itemIndex < 0) { itemIndex += this.items.length }
      while (itemIndex >= this.items.length) { itemIndex -= this.items.length }
      this.selectItem(this.items[itemIndex])
    },
    centerItem () {
      if (this.item && this.item.displaySettings && this.item.displaySettings.type === 'station' && this.item.displaySettings.center) {
        this.$bus.$emit('map.center', this.item.displaySettings.center)
      }
    },
    setLoading (value) {
      this.loading = value
    },
  }
}
</script>