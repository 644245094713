<template>
  <div class="fc-map__page -with-header -full-width">
    <div class="fc-map__page-header">
      <v-row align="center">
        <v-col>
          <h1 class="title">{{ $t('menu.reports') }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn :to="{name: 'main'}" icon exact>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="fc-map__page-body"  style="overflow: unset">
      <div class="body-1">
        <v-card-text>
        <v-row>
          <v-col
            cols="10"
            >
            <fields v-model="instance" :fields="fields" />
          </v-col>
          <v-col
            cols="2"
            >
            <v-card-actions class="px-6 pb-4">
              <v-spacer />
              <v-btn :loading="loading" color="primary" @click="find">{{ $t('actions.find') }}</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        </v-card-text>
      </div>
    </div>
    <template>
      <div class="fc-map__page-body pb-3">
          <v-data-table
            :headers="headers"
            :items="items"
          >
            <template v-slot:item="{item}">
              <tr>
                <td v-for="header in headers" :key="header.value">
                  <template>
                    {{ getItemValue(item, header) }}
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
    </template>
    <div class="fc-map__page-body pt-3 pb-3" style="overflow: unset; display: none">
      <div class="body-1">
        <div v-for="(value, key) in totals" :key="value"><strong>{{ key }}</strong>: {{value}}</div>
      </div>
    </div>
    <div class="fc-map__page-footer" />
  </div>
</template>

<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'
// import ReportItems from '~/components/report/ReportItems'
import configFields from '~/config/fields'

export default {
  mixins: [generalFormMixin],
  data () {
    return {
      items: [],
      header: [],
      totals: [],
      instance: {
        from: null,
        to: null
      }
    }
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    fields () {
      return configFields.reportFilter
      .filter(field => {
          if (!field.permission) { return true }
          return (this.$store.state.auth.user.permissions || []).includes(field.permission)
        })
    },
    headers () {
      var header = [
          {text: this.$t('report.pivot'), value: 'rtu'},
        ]

        if (this.isAdmin)
          header.push({text: this.$t('report.organization'), value: 'organization'})

        header.push(
          {text: this.$t('report.date'), value: 'date'},
          {text: this.$t('report.status'), value: 'status'},
          {text: this.$t('report.position'), value: 'position'},
          {text: this.$t('report.speed'), value: 'speed'},
          {text: this.$t('report.duration'), value: 'duration'}
          )
      return header
    }
  },
  created () {
    this.loadItems()
  },
  methods: {
    getItemValue (item, header) {
      const pathParts = (header.field || header.value || '').split('.')
      let result = item
      for (let i = 0; i < pathParts.length; i++) {
        if (result && Object.prototype.hasOwnProperty.call(result, pathParts[i])) {
          result = result[pathParts[i]]
        } else {
          return header.emptyPlaceholder || null
        }
      }
      return result || header.emptyPlaceholder
    },
    loadItems (force) {
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'report', force}))
        .then((response) => {
          this.items = response.data.items
          this.totals = response.data.total
          if (this.items?.length) {
            this.instance.from = moment(this.items[this.items.length - 1].date, 'DD.MM.YYYY HH:I').format('YYYY-MM-DD')
            this.instance.to = moment(this.items[0].date, 'DD.MM.YYYY HH:I').format('YYYY-MM-DD')
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'report', data}))
        .then((response) => {
          this.items = response.data.items
          this.totals = response.data.total
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
